import React from "react";
// import PropTypes from "prop-types";
// import { graphql } from "gatsby";

import { Container } from "react-bootstrap";

import politicaPdf from "../../static/Politica_de_tratamiento_de_datos_personales.pdf";

import "../style/main.scss";

// export const query = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//         keywords
//         description
//       }
//     }
//   }
// `;

const Privacy = () => {
  return (
    <>
      <Container>
        <div>
          <h1>Protección de Datos Personales - Habeas Data </h1>
          <div>
            {" "}
            A través de la Ley 1581 de 2012 y el Decreto 1377 de 2013, se desarrolla el derecho
            constitucional que tienen todas las personas a conocer, suprimir, actualizar y
            rectificar todo tipo de datos personales recolectados, almacenados o que hayan sido
            objeto de tratamiento en bases de datos del proyecto Lucy Tejada Táctil.{" "}
          </div>
          <div>
            El proyecto Lucy Tejada Táctil, en su calidad de encargado y/o responsable del
            tratamiento de datos personales, solicita a los participantes en sesiones virtuales y
            talleres, y a los ciudadanos en general, de conformidad con la Ley 1581 de 2012 y el
            Decreto 1377 de 2013, autorización para continuar con el tratamiento de sus datos
            personales, impartiendo una autorización o negación de la misma, a través de los
            diferentes canales de atención al cliente que tiene el proyecto, como son:
          </div>
          <div> Correo electrónico: lucytejadatactil@gmail.com</div>
          <div> Comunicación telefónica y/o WhatsApp : +57 302 440 8069</div>
          <div>
            La información y datos personales disponibles en nuestras bases de datos se encuentran
            protegidos por la Ley de Protección de Datos Personales y podrán ser procesados,
            recolectados, almacenados, usados, circulados, suprimidos, compartidos, actualizados,
            transmitidos y/o transferidos a terceros, nacionales e internacionales, para las
            finalidades contenidas en las políticas de tratamiento de datos.
          </div>
          <a href={politicaPdf} download>
            Política de tratamiento de datos personales.pdf
          </a>
          <div>
            De acuerdo con lo establecido en el Decreto 1377 de 2013, si transcurridos treinta (30)
            días hábiles a partir de la fecha de publicación de esta comunicación, el proyecto Lucy
            Tejada Táctil no recibe manifestación alguna, podrá continuar con el tratamiento de la
            información personal.
          </div>
        </div>
      </Container>
    </>
  );
};

Privacy.propTypes = {};

Privacy.defaultProps = {};

export default Privacy;
